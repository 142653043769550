<template>
  <section id="subgroups">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("Subgroups") }}</h1>
        <h5 class="text-primary">
          {{ $t("subgroupList") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="primary"
          @click="actionsSubgroup('create')"
        >
          <feather-icon
            icon="FilePlusIcon"
            size="16"
            class="mr-50"
          />
          <span class="text-nowrap">{{ $t("addSubgroup") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-table
        ref="refUserListTable"
        :items="allSubgroups"
        :fields="tableColumns"
        show-empty
        :empty-text="$t('emptyText')"
        responsive
        primary-key="id"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media
            vertical-align="center"
            @click="actionsSubgroup('update', data.item.id)"
          >
            {{ data.item.name }}
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="data.item.isActive ? `light-success` : `light-danger`
            "
            class="text-capitalize"
          >
            <feather-icon
              :icon="data.item.isActive ? 'CheckIcon' : 'XIcon'"
              size="18"
              class="mr-50"
            />
            {{
              data.item.isActive
                ? $t("dataGeneric.active")
                : $t("dataGeneric.inactive")

            }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          <div class="d-flex align-items-center">
            {{ formatearFechaHora(data.item.createdAt) }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="float-right">
            <b-link
              class="ml-1"
              @click="actionsSubgroup('update', data.item.id)"
            >
              <feather-icon
                icon="EditIcon"
                size="17"
                class="text-warning"
              />
            </b-link>
            <b-link
              class="ml-1"
              @click="actionsSubgroup('delete', data.item.id)"
            >
              <feather-icon
                icon="XCircleIcon"
                size="17"
                class="text-danger"
              />
            </b-link>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="subgroup-view"
      ref="subgroup-view"
      size="lg"
      hide-footer
      :title="$t('Subgroup')"
      @hidden="resetModal"
    >
      <div class="d-block">
        <subgroups-view
          :id="id"
          @refresh="fetchData()"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="resetModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BLink,
  BMedia,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, isEmpty, showToast,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import SubgroupsView from './subgroupsView.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BLink,
    BMedia,
    SubgroupsView,
    BBadge,
    BButton,
  },
  data() {
    return {
      userData: getUserData(),
      isEmpty,
      tableColumns: [
        { key: 'name', label: this.$t('Subgrupo') },
        { key: 'isActive', label: this.$t('code.status') },
        { key: 'createdAt', label: this.$t('subscriptions.createdAt') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },

      ],
      allSubgroups: [],
      id: null,

    }
  },
  mounted() {
    this.fetchData()
  },

  methods: {
    resetModal() {
      this.show = false

      this.$nextTick(() => {
        this.$bvModal.hide('subgroup-view')
      })
    },
    actionsSubgroup(action, id = null) {
      this.id = id

      if (action === 'delete') {
        this.$swal({
          title: this.$t('eliminarSubgrupo'),
          text: this.$t('eliminarSubgrupoInfo'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('code.confirm'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            axios
              .post('', {
                query: `
                mutation{
                    deleteSubgroup(id:"${id}"){
                        found
                        deletedId
                    }
                }
                `,
              })
              .then(res => {
                messageError(res, this)
                showToast(this.$t('code.updateData'), 1, this)
                this.fetchData()
              })
              .catch(() => {
                showToast(this.$t('code.updateDataError'), 2, this)
                this.show = false
              })
          }
        }).catch(() => {

        })
      } else {
        this.$refs['subgroup-view'].show()
      }
    },
    formatearFechaHora(fechaISO) {
      if (fechaISO) {
        const fecha = new Date(fechaISO)
        const dia = fecha.getDate().toString().padStart(2, '0')
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0')
        const anio = fecha.getFullYear()
        const hora = fecha.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })

        return `${dia}/${mes}/${anio} ${hora}`
      }
      return '--/--/---- --:--'
    },
    fetchData() {
      axios.post('', {
        query: `
        {
            allSubgroups(client:"${this.userData.profile.client.id}") {
                edges {
                    node {
                        id
                        name
                        createdAt
                        isActive
                    }
                }
            }
        }`,
      })
        .then(res => {
          messageError(res, this)
          const { edges } = res.data.data.allSubgroups
          this.allSubgroups = edges.map(e => e.node)
        })
        .catch(err => { console.log(err) })
    },

  },
}
</script>
